export class InstaIzvestajPostModel {
    datumOd: Date;
    datumDo: Date;

    constructor(datumOd: Date, datumDo: Date) {
        this.datumOd = datumOd;
        this.datumDo = datumDo;
    }
}

export interface IInstaIzvestaj {
    lokacii: IInstaIzvestajLokacija[];
    vkupnoZaliha: number;
    vkupnoProdadeni: number;
    vkupnoProdazna: number;
    stavki: IInstaIzvestajStavka[];
}

export interface IInstaIzvestajLokacija {
    lokacija: string;
    zaliha: number;
    prodadeni: number;
    prodazna: number;
}

export interface IInstaIzvestajStavka {
    id: number;
    sifra: string;
    naziv: string;
    vkupnoZaliha: number;
    vkupnoProdadeni: number;
    vkupnoProdazna: number;
    zaliha: number[];
    prodadeni: number[];
    prodazna: number[];
}
