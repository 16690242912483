// ANGULAR
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MessageDialogService } from './message-dialog.service';
import { IzvestaiService } from './izvestai.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        DecimalPipe,
        DatePipe,
        IzvestaiService,
        MessageDialogService
    ]
})
export class CoreModule {
}
