import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InstaIzvestajPostModel } from '../models/insta-izvestaj';
import { IInstaIzvestaj } from '../models/insta-izvestaj';

@Injectable()
export class IzvestaiService {

    constructor(private http: HttpClient) {}

    get(model: InstaIzvestajPostModel): Observable<IInstaIzvestaj> {
        return this.http.post<IInstaIzvestaj>(`/IzvestaiApi/Izvestai/Get`, model);
    }

}
