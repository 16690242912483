import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IzvestaiService } from './core/izvestai.service';
import { MessageDialogService } from './core/message-dialog.service';
import { IInstaIzvestaj } from './models/insta-izvestaj';
import { IInstaIzvestajStavka } from './models/insta-izvestaj';
import { InstaIzvestajPostModel } from './models/insta-izvestaj';
import { IInstaIzvestajLokacija } from './models/insta-izvestaj';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    activeTab = 0;
    forma: FormGroup;
    izvestaj: IInstaIzvestaj;

    vkupno = [] as number[];
    lokacii = [] as IInstaIzvestajLokacija[];

    dataSource = new MatTableDataSource([] as IInstaIzvestajStavka[]);
    dataColumns = ['artikl', 'vkupno'];

    constructor(public messageDialogService: MessageDialogService,
        public overlayContainer: OverlayContainer,
        public formBuilder: FormBuilder,
        public dialogService: MatDialog,
        public izvestaiService: IzvestaiService) {
        this.overlayContainer.getContainerElement().classList.add('main-light-theme');
        this.forma = this.formBuilder.group({
            datumOd: new FormControl(new Date(), { validators: [Validators.required] }),
            datumDo: new FormControl(new Date(), { validators: [Validators.required] })
        });
    }

    prebaraj() {
        const forma = this.forma.value;
        const model = new InstaIzvestajPostModel(forma.datumOd, forma.datumDo);
        this.messageDialogService.showSpinner();
        this.izvestaiService.get(model).subscribe(result => {
            this.izvestaj = result;
            this.dataColumns = ['artikl', 'vkupno'];
            this.lokacii = [] as any[];
            for (const lokacija of this.izvestaj.lokacii) {
                this.dataColumns.push(lokacija.lokacija);
                this.lokacii.push(lokacija);
            }
            this.dataSource.data = result.stavki;
            this.messageDialogService.hideSpinner();
        });
    }

    tabChanged($event: MatTabChangeEvent) { this.activeTab = $event.index; }

    filter(filterValue: string) { this.dataSource.filter = filterValue.trim().toLowerCase(); }
}
