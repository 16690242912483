<div class="main-light-theme">
    <mat-toolbar class="mat-elevation-z6"
                 color="primary">
        <button mat-button
                routerLink="">
            АМБАР
        </button>
    </mat-toolbar>
    <div class="mdc-layout-grid">
        <div class="mdc-layout-grid__inner pt-1">
            <mat-card class="mdc-layout-grid__cell--span-12">
                <mat-card-content class="mdc-layout-grid__inner">
                    <div>
                        <div class="mdc-layout-grid__cell--span-3">
                            &nbsp;
                        </div>
                        <form (keydown.enter)="$event.preventDefault()"
                              [formGroup]="forma"
                              class="mdc-layout-grid__cell--span-6"
                              novalidate>
                            <div class="mdc-layout-grid__inner">
                                <mat-form-field class="mdc-layout-grid__cell--span-12">
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input formControlName="datumOd"
                                               matStartDate
                                               placeholder="Датум од">
                                        <input formControlName="datumDo"
                                               matEndDate
                                               placeholder="Датум до">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle [for]="picker"
                                                           matSuffix>
                                    </mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                </mat-form-field>
                                <div class="mdc-layout-grid__cell--span-12 text-right">
                                    <button (click)="prebaraj();"
                                            (keydown.enter)="prebaraj();"
                                            [disabled]="forma.invalid"
                                            color="primary"
                                            mat-raised-button>
                                        Пребарај
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div *ngIf="izvestaj"
                         class="mdc-layout-grid__cell--span-12 mdc-layout-grid__inner">
                        <mat-tab-group (selectedTabChange)="tabChanged($event);"
                                       class="mdc-layout-grid__cell--span-12">
                            <mat-tab label="Продажба износ"></mat-tab>
                            <mat-tab label="Продажба количина"></mat-tab>
                            <mat-tab label="Моментална залиха"></mat-tab>
                        </mat-tab-group>
                        <div class="mdc-layout-grid__cell--span-12 mdc-layout-grid__inner">
                            <mat-form-field class="mdc-layout-grid__cell--span-12">
                                <input (keyup)="filter($event.target.value)"
                                       matInput
                                       placeholder="Пребарај">
                            </mat-form-field>
                            <table [dataSource]="dataSource"
                                   class="mdc-layout-grid__cell--span-12"
                                   mat-table>
                                <ng-container matColumnDef="artikl">
                                    <th *matHeaderCellDef
                                        mat-header-cell>
                                        Артикл
                                    </th>
                                    <td *matCellDef="let element"
                                        mat-cell>
                                        {{ element.sifra }} - {{ element.naziv }}
                                    </td>
                                    <td *matFooterCellDef
                                        class="text-right"
                                        mat-footer-cell>
                                        <b>
                                            Вкупно:<br *ngIf="activeTab === 0"/>
                                            <span *ngIf="activeTab === 0">Заработка:</span>
                                        </b>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="vkupno">
                                    <th *matHeaderCellDef
                                        class="text-right"
                                        mat-header-cell>
                                        Вкупно<br/>
                                        <span *ngIf="activeTab === 0">{{ izvestaj.vkupnoProdazna | number: '1.0-0' }}</span>
                                        <span *ngIf="activeTab === 1">{{ izvestaj.vkupnoProdadeni | number: '1.0-0' }}</span>
                                        <span *ngIf="activeTab === 2">{{ izvestaj.vkupnoZaliha | number: '1.0-0' }}</span>
                                    </th>
                                    <td *matCellDef="let element"
                                        class="text-right"
                                        mat-cell>
                                        <span *ngIf="activeTab === 0">{{ element.vkupnoProdazna | number: '1.0-0' }}</span>
                                        <span *ngIf="activeTab === 1">{{ element.vkupnoProdadeni | number: '1.0-0' }}</span>
                                        <span *ngIf="activeTab === 2">{{ element.vkupnoZaliha | number: '1.0-0' }}</span>
                                    </td>
                                    <td *matFooterCellDef
                                        class="text-right"
                                        mat-footer-cell>
                                        <b>
                                            <span *ngIf="activeTab === 0">{{ izvestaj.vkupnoProdazna | number: '1.0-0' }}</span>
                                            <br/>
                                            <span *ngIf="activeTab === 0">{{ izvestaj.vkupnoZarabotka | number: '1.0-0' }}</span>
                                            <span *ngIf="activeTab === 1">{{ izvestaj.vkupnoProdadeni | number: '1.0-0' }}</span>
                                            <span *ngIf="activeTab === 2">{{ izvestaj.vkupnoZaliha | number: '1.0-0' }}</span>
                                        </b>
                                    </td>
                                </ng-container>
                                <ng-container *ngFor="let lokacija of lokacii; let i = index"
                                              [matColumnDef]="lokacija.lokacija">
                                    <th *matHeaderCellDef
                                        class="text-right"
                                        mat-header-cell>
                                        {{ lokacii[i].lokacija }}<br/>
                                        <span *ngIf="activeTab === 0">{{ lokacii[i].prodazna | number: '1.0-0' }}</span>
                                        <span *ngIf="activeTab === 1">{{ lokacii[i].prodadeni | number: '1.0-0' }}</span>
                                        <span *ngIf="activeTab === 2">{{ lokacii[i].zaliha | number: '1.0-0' }}</span>
                                    </th>
                                    <td *matCellDef="let element"
                                        class="text-right"
                                        mat-cell>
                                        <span *ngIf="activeTab === 0">{{ element.prodazna[i] | number: '1.0-0' }}</span>
                                        <span *ngIf="activeTab === 1">{{ element.prodadeni[i] | number: '1.0-0' }}</span>
                                        <span *ngIf="activeTab === 2">{{ element.zaliha[i] | number: '1.0-0' }}</span>
                                    </td>
                                    <td *matFooterCellDef
                                        class="text-right"
                                        mat-footer-cell>
                                        <b>
                                            <span *ngIf="activeTab === 0">{{ lokacii[i].prodazna | number: '1.0-0' }}</span>
                                            <span *ngIf="activeTab === 1">{{ lokacii[i].prodadeni | number: '1.0-0' }}</span>
                                            <span *ngIf="activeTab === 2">{{ lokacii[i].zaliha | number: '1.0-0' }}</span>
                                        </b>
                                    </td>
                                </ng-container>
                                <tr *matHeaderRowDef="dataColumns"
                                    mat-header-row>
                                </tr>
                                <tr *matRowDef="let row; columns: dataColumns;"
                                    mat-row>
                                </tr>
                                <tr *matFooterRowDef="dataColumns"
                                    mat-footer-row>
                                </tr>
                            </table>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
